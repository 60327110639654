import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as G from '../GameData'
import ImageViewer from 'iv-viewer';

// or
//const ImageViewer = require('iv-viewer').default; 

// Import css

export default function Camera() {

    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const id = React.useId();
    React.useEffect(()=>{
        let image =  document.getElementById(id)
        const viewer = new ImageViewer(image, {maxZoom:500,zoomValue:0}); 
    },[])
    return (
        <div >
            <div className='mapdownload' onClick={(e)=>{
               
              
                window.open(
                    "images/map.png", "_blank");
            }}>Download Map Image</div>
             <img  id={id} height={window.outerHeight} width={window.outerWidth} src="images/map.png"  />
        </div>
    )

}