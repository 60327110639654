import * as React from 'react';
import {Html5QrcodeScanner,Html5QrcodeScanType} from "html5-qrcode"
let html5QrcodeScanner:any  = undefined
let lastUrl = ""
export default function Camera() {

  //  const [lastUrl, setLastUrl] = React.useState("");
    const id = React.useId();
    React.useEffect(()=>{
        function onScanSuccess(decodedText:any, decodedResult:any) {
            // handle the scanned code as you like, for example:
            console.log(`Code matched = ${decodedText}`, decodedResult);
          
            if(lastUrl != decodedText && window.location.href != decodedResult)
            {
                lastUrl = decodedText
                html5QrcodeScanner.pause()
                window.location.replace(decodedText)
            
            }
          }
          
          function onScanFailure(error:any) {
            // handle scan failure, usually better to ignore and keep scanning.
            // for example:
            console.warn(`Code scan error = ${error}`);
          }
          
          //@ts-ignore
           html5QrcodeScanner = new Html5QrcodeScanner(
            id,
            { fps: 10, qrbox: {width: 250, height: 250} , rememberLastUsedCamera: true,
            // Only support camera scan type.
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA] } as any,
            /* verbose= */ false);
           
            html5QrcodeScanner.render(onScanSuccess, onScanFailure);
            
           
      
    },[])
 
    return (
        <div id={id} style={{width:'100%'}}>Hello</div>
    );
}