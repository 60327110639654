
import path from 'path';
import React, { useRef, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useLocation
} from "react-router-dom";
import Camera from '../Pages/Camera'
import Game from '../Pages/Game'
import Cheat from '../Pages/Cheat'
import Map from '../Pages/Map'
import Splash from '../Pages/Splash';
import Spell from '../Pages/Spell';
import Ending from '../Pages/Ending';
/*
class TeamWrapperProps {
  projectTab?: boolean  =false
  chartTab?: boolean  =false
  orgTab?:boolean =false
}
export  function TeamWrapper(props:TeamWrapperProps) {
  let params = useParams();
  //return (<div>{params.id}</div>)
  return <Team teamId={params.id as string} projectTab={props.projectTab as boolean} chartTab={props.chartTab as boolean} orgTab={props.orgTab as boolean} />
}
export  function StaffWrapper() {
  let params = useParams();
  //return (<div>{params.id}</div>)
  return <Staff staffId={params.id as string} />
}
export  function OrgWrapper() {
  let params = useParams();
  //return (<div>{params.id}</div>)
  return <Org staffId={params.id as string} />
}
export  function SixPagerWapper() {
  let params = useParams();
  //return (<div>{params.id}</div>)
  return <SixPagerDetail sixPagerId={params.id as string} />
}*/

export  function GameWrapper() {
    let params = useParams();
    //return (<div>{params.id}</div>)
    return <Game location={params.location as string} />
  }
export default function NestingExample() {

  const { pathname } = useLocation()
  console.log(pathname)


  return (
  
      
 
   <Routes>
     <Route index element={<Splash />} />
      <Route path={`camera`} element={<Camera />} />
      <Route path={`map`} element={<Map />} />
      <Route path={`cheat`} element={<Cheat />} />
      <Route path={`game`} element={<GameWrapper />} />
      <Route path={`spell`} element={<Spell />} />
      <Route path={`ending`} element={<Ending />} />
      <Route path={`game/:location`} element={<GameWrapper />} />
      
     
      </Routes>
   
  )
}
