

function csvToArray(text: any) {
    let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
    for (l of text) {
        if ('"' === l) {
            if (s && l === p) row[i] += l;
            s = !s;
        } else if (',' === l && s) l = row[++i] = '';
        else if ('\n' === l && s) {
            if ('\r' === p) row[i] = row[i].slice(0, -1);
            row = ret[++r] = [l = '']; i = 0;
        } else row[i] += l;
        p = l;
    }
    return ret;
};

function csvToObj<T>(text: string, n: () => T, idField?: string): Array<T> {
    let retval = new Array<T>()
    let staffArray = csvToArray(text);
    let headers = staffArray[0]
    let first = true;
    for (var i in staffArray) {
        if (first) {
            first = false;

        }
        else {
            let item = staffArray[i]
            let s = n();
            for (var x in item) {
                let header = headers[x].split(' ').join("")
                header = header.split('-').join("")
                header = header.split('/').join("")
                //@ts-ignore
                s[header] = item[x];
                if (idField && header == idField) {

                    //@ts-ignore
                    s["id"] = item[x];
                }
            }

            retval.push(s)
        }
    }
    return retval
}
function fixTeamNameToWorkWithRoutes(team?: string): string | undefined {
    return team?.replaceAll('/', ' ');
}
export class GameLocationState {
    LocationId: string = ""
    Text: string = ""
    Image: string = ""
    Vars: any = {}

}
export class Game {
    Locations: Array<GameLocationState> = new Array<GameLocationState>()
    State: any;
    LocationHistory: Array<GameLocationState> = new Array<GameLocationState>()
    saveState() {
        if (localStorage.getItem("debug_mode") != "TRUE")
            localStorage.setItem("game", JSON.stringify(this));
    }
    VisitLocation(locationId: string): GameLocationState | undefined {
        let locations = this.Locations.filter(x => x.LocationId == locationId).reverse()
        for (var i in locations) {
            let l: any = locations[i]
            let match = true
            for (let x in l) {

                if (l[x] == "TRUE" && this.State[x] != "TRUE") {
                    match = false
                }
                else {

                }


            }
            if (match) {
                for (let x in l) {
                    if (l[x] == "SET") {
                        this.State[x] = "TRUE"
                        if (x.toUpperCase() == "RESET") {
                            this.State = {}
                        }
                        this.saveState();

                    }

                }
                return locations[i]
            }
        }
        return undefined
    }
}
let _gameState: Game;

export async function loadGameContent(): Promise<Array<GameLocationState>> {
    return new Promise<Array<GameLocationState>>(async (resolve, reject) => {
        try {
            let gameReq = fetch('https://docs.google.com/spreadsheets/d/1SBMBziAlhgPNixI-AaZ1Jopv_MWURgs8bR9eNMrNSV8/export?format=csv&id=1SBMBziAlhgPNixI-AaZ1Jopv_MWURgs8bR9eNMrNSV8&gid=0');

            let result = await gameReq
            let text = await result.text()
            // let json = JSON.stringify(text);

            resolve(csvToObj<GameLocationState>(text, () => { return new GameLocationState() }))
        }
        catch (e) {
            reject(e)
        }
    })

}
export function Init(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
        try {

            if (_gameState) {
                return resolve()
            }

            let gameJson = localStorage.getItem("game");
            if (gameJson) {
                let g = JSON.parse(gameJson)
                _gameState = new Game();
                for (var i in g) {
                    //@ts-ignore
                    _gameState[i] = g[i]
                }
                return resolve()
            }

            _gameState = new Game();
            //let gameReq = fetch('https://docs.google.com/spreadsheets/d/1SBMBziAlhgPNixI-AaZ1Jopv_MWURgs8bR9eNMrNSV8/export?format=csv&id=1SBMBziAlhgPNixI-AaZ1Jopv_MWURgs8bR9eNMrNSV8&gid=0');

            //let result = await gameReq
            //let text = await result.text()
            // let json = JSON.stringify(text);

            _gameState.Locations = await loadGameContent();//csvToObj<GameLocationState>(text, () => { return new GameLocationState() });
            _gameState.State = {};


            ;
            resolve()
        }
        catch (e) {
            reject(e)
        }
    })
}


export default function () {
    return _gameState
}
