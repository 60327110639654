import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CameraIcon from '@mui/icons-material/CameraAlt'
import MapIcon from '@mui/icons-material/Map'
import SettingsIcon from '@mui/icons-material/Settings'
import HistoryIcon from '@mui/icons-material/History'
import { BottomNavigation, BottomNavigationAction, Container, CssBaseline, Paper } from '@mui/material';

import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SpellIcon from '@mui/icons-material/AutoFixHigh';
import { useNavigate } from 'react-router-dom';
import Router from './Router'
import G from '../GameData'
import ImageCache from '../Pages/ImageCach';
export default function ButtonAppBar() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [counter, setCounter] = React.useState<number>(0);
   
    return (
        <div >
            <CssBaseline />
             
            <Container style={{padding:0}}>
     <Router/>
            </Container>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction onClick={()=>{
                            navigate("camera")
                            setCounter(0);
                          
                    }} label="Scan" icon={<CameraIcon />} />
                    <BottomNavigationAction label="Map" icon={<MapIcon />} onClick={()=>{
                            navigate("map")
                            setCounter(0);
                         
                    }} />
                       <BottomNavigationAction label="Spell" icon={<SpellIcon />} onClick={()=>{
                            navigate("spell")
                            setCounter(0);
                         
                    }} />
                    <BottomNavigationAction label="Settings" icon={<SettingsIcon />}  onClick={()=>{
                            navigate("cheat")
                           
                                setCounter(counter+1);
                                if(counter > 5)
                                {
                                    let item = localStorage.getItem("cheat")
                                    if(item == "TRUE")
                                    {
                                        localStorage.removeItem("cheat")
                                      
                                        alert('Cheat mode off.')
                                    }
                                    else
                                    {
                                    localStorage.setItem("cheat","TRUE")
                                  
                                    alert('CHEAT MODE!!!!!')
                                    }
                                    setCounter(0);
                                }
                                setTimeout(()=>{
                                    setCounter(0);
                                },5000)
                            }}
                    />
                </BottomNavigation>
            </Paper>
            <div style={{display:"none"}}>
                {G().Locations.map(x=>(
                    <ImageCache src={x.Image} key={"cache"+x.Image} />
                ))}
            </div> 

        </div>
    );
}