import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as G from '../GameData'
import ImageViewer from 'iv-viewer';

// or
//const ImageViewer = require('iv-viewer').default; 

// Import css

export default function Camera() {

   
    return (
        <div >
            <video  controls autoPlay style={{width:"100%"}} >
  <source src="images/Ending1.mp4" type="video/mp4" />

          
</video>
<div style={{padding:10}}> Congratulations!! You have saved the Kingdom of Henry for the Gargoyle!!!  He has turned back to stone, and order will return to the land.</div>
        </div>
    )

}