import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as G from '../GameData'
import ImgCache from '../ImgCache'
import ImageCache from './ImageCach';
import { Box, nativeSelectClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { debug } from 'console';
//https://docs.google.com/spreadsheets/d/1SBMBziAlhgPNixI-AaZ1Jopv_MWURgs8bR9eNMrNSV8/edit?usp=sharing




export class PersistState {
    static get(key: string): GameState {
        let str = localStorage.getItem(key)
        try {
            if (str)
                return JSON.parse(str);
        }
        catch (e) {
            console.error(e);

        }
        return new GameState;
    }
    static set(key: string, value: GameState) {
        localStorage.setItem(key, JSON.stringify(value))

    }

}

class GameState {
    visitGargoyle: boolean = false;

}
interface IGameProps {
    location: string;
}
export default function Game(props: IGameProps) {

    const [location, setLocation] = React.useState<G.GameLocationState | undefined>(undefined)
    const [locationUrl, setLocationUrl] = React.useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const elementRef = React.useRef<any>();

    React.useEffect(() => {
        function speak(text: any) {
            // Create a new instance of SpeechSynthesisUtterance.
            var msg = new SpeechSynthesisUtterance();

            // Set the text.
            msg.text = text;

            // Set the attributes.
            msg.volume = 1
            msg.rate = 1
            msg.pitch = 1
            let vText = "Alex"
            if (vText) {
                let v = speechSynthesis.getVoices().find(function (voice) { return voice.name == vText });;
                if (v)
                    msg.voice = v
            }
            // If a voice has been selected, find the voice and set the
            // utterance instance's voice attribute.
            window.speechSynthesis.speak(msg);
        }
        ;
        let l = G.default().VisitLocation(props.location)
        
        if (l) {
            setLocation(l);
            setLocationUrl(undefined)
            debugger
            if(G.default().State["WonGame"] == "TRUE")
            {
                navigate("/ending")
            }
            /* ImgCache(l.Image).then((data)=>{
                     setLocationUrl(data)
             })*/
        }

        /*if (elementRef.current) {
            if (elementRef.current.innerHTML != lastText) {
                console.log(props.location + "--" + lastText + " -- " + elementRef.current.innerHTML)
                setLastText(elementRef.current.innerHTML)
                speak(elementRef.current.innerHTML)

            }
        }*/

    }, [props.location])

    return (
        <div style={{padding:10}}>
            <Typography variant='h5' style={{textAlign: "center"}} >
                {location?.LocationId}
            </Typography>

            <div>
            <ImageCache src={location?.Image as string} key="game" />
            </div>
            <Typography style={{padding:10}} variant='body1'>
                {location?.Text}
                {G.default().State["Start"] != "TRUE" && (
                    <div> ( Visit the rabbit to start the quest. ) </div>
                )}
            </Typography>



        </div>
    );
}