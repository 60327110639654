import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as G from '../GameData'
import ImageViewer from 'iv-viewer';

// or
//const ImageViewer = require('iv-viewer').default; 

// Import css

export default function Camera() {
    const [playVideo, setPlayVideo] = React.useState<boolean>(false);

    return (
        <div style={{ height: window.innerHeight, textAlign:"center" }} className="splash">
            {!playVideo && (
                <Button variant="contained" style={{
                    position: "absolute",
                    bottom: "100px",
                    width: "50%",
                    fontSize: "1em",
                    left:"25%",
                    background: "#5d5d5d42",
                    color: "yellow"
                
                }} onClick={() => {
                    window.open("https://drive.google.com/file/d/10a44U8CypLclcRqikkzjYBTEuvNklI3C/view", "_blank");
                }}>Play Video</Button>
            )}
         


        </div>
    )

}   