import * as React from 'react';
import { Button, FormControlLabel, Stack, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as G from '../GameData'
import { loadGameContent } from '../GameData';
export default function Camera() {

    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    let dedup = new Array<string>()
    G.default().Locations.forEach(x=>{
      if(dedup.indexOf(x.LocationId)==-1)
      {
          dedup.push(x.LocationId)
      }
    })
    let debugMode = localStorage.getItem("debug_mode") == "TRUE"
    let states = new Array<string>()
    for(var i in G.default().State)
    {
       states.push(i+" : "+G.default().State[i]);
    }
    return (
      <div style={{textAlign:'center', padding:20}}>
        <div>june 4 1:14 PM</div>
        <div> <Button variant="contained" onClick={()=>{
                    localStorage.removeItem("game")
                    localStorage.removeItem("debug_mode")
                    localStorage.removeItem("cheat")
                    window.location.reload();
            }}>Reset Game</Button>
            </div>
            <div>___</div>
            <div>
            <Button variant="contained" onClick={async ()=>{
                      G.default().Locations = await loadGameContent();
                      G.default().saveState();
                     window.location.reload();
            }}>Reset Content</Button>
            </div>
         <FormControlLabel control={<Switch value={debugMode} onChange={(e)=>{
           e.target.checked?localStorage.setItem("debug_mode","TRUE"):localStorage.setItem("debug_mode","FALSE")
         }} />} label="Debug" /> 
         {states.map((x)=>(
            <div>
              {x}
            </div>
         ))}
{localStorage.getItem("cheat") == "TRUE" && (
                 <Stack spacing={2} direction="column">
                   CHEAT MODE
       {dedup.map(x=>(
            <Button variant="contained" onClick={()=>{
                      navigate("/game/"+x);
            }}>{x}</Button>))}
        
      </Stack>
    )}
      </div>)
    
}