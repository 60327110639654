import React, { useEffect } from "react";


interface ImageCacheProps {
    src: string;
    key: string;
   
}
let cache: any = {}

export default function ImageCache(props: ImageCacheProps) {

    const [value, setValue] = React.useState(0);
    const id = React.useId();
    useEffect(() => {
        if(!props.src)
            return;
      
        if(props.src.indexOf('images') ==0)
            return;
        if (cache[props.src]) {
          
            //@ts-ignore
            document.getElementById(id).appendChild(cache[props.src]);
            return;
        }
        var img = document.createElement('img');
        img.height= 200
        img.src =   props.src;

        cache[props.src] = img;
        //@ts-ignore
        document.getElementById(id).appendChild(cache[props.src]);
    }, [props.src])
    
    if(props.src && props.src.indexOf('images') ==0)
    {
        return (<div style={{textAlign:"center"}}><img height={200} src={props.src} /></div>)
    }
    return (
        <div id={id} >

        </div>
    )


}