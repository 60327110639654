import * as React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as G from '../GameData'
import ImageViewer from 'iv-viewer';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// or
//const ImageViewer = require('iv-viewer').default; 
import SpellIcon from '@mui/icons-material/AutoFixHigh';
// Import css
interface SymbolProps {
    charCode: string
}
function Symbol(props: SymbolProps) {
    return (
        <div style={{ cursor: "pointer" }}>
            <span style={{ fontSize: "3em" }}>{props.charCode[0]}</span><span style={{
                fontSize: "1em",
                marginTop: "-10px",
                position: "fixed"
            }}>{props.charCode[1]}</span>
        </div>
    )
}
function SymbolSmall(props: SymbolProps) {
    return (
        <div style={{ padding: 0, fontSize:"1.0em" }}>
            {props.charCode[0]}
            <span style={{
               fontSize: "0.5em",
               position: "relative",
              
               top: "-1em"
                

            }}>{props.charCode[1]}</span>
        </div>
    )
}
const blackAns = [null, null, null, null, null, null]
function getDefaultAnswers(): Array<number | null> {
    if (G.default().State["spellAnswers"]) {
        return G.default().State["spellAnswers"];

    }
    return blackAns;//[undefined,undefined,undefined,undefined,undefined,undefined]
}
function getDefaultSpell():boolean
{
     return G.default().State["hasSpell"] == "TRUE"
}
export default function Camera() {
    const [playVideo, setPlayVideo] = React.useState<boolean>(false);
    const [answers, setAnswers] = React.useState<Array<number | null>>(getDefaultAnswers());
    const [wrongAnswer, setWrongAnswer] = React.useState<boolean>(false);
    const [castSpell, setCastSpell] = React.useState<boolean>(false);
    const [hasSpell,setHasSpell] = React.useState<boolean>(getDefaultSpell());
    const cords = ['☆▷', '☓♢', 'O☆', '▷☾', '☓☆', '☾☓', '☾O', 'O♢', '♢▷']
    const rightAnswer = [2, 4, 5, 5, 6, 5];
    const rightAnswer2 = [2, 4, 5, 3, 6, 5];
    //  396676  


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const ItemBorder = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        border: "2px white solid",

        color: theme.palette.text.secondary,
    }));
    function clickValue(value: number) {
        let ans1 = [...answers]
        let ind = ans1.indexOf(null);
        if (ind > -1) {
            ans1[ind] = value;
        }
        setAnswers(ans1)
        G.default().State["spellAnswers"] = ans1;
        G.default().saveState()
    }
    let canCast = answers.filter(x => x != null).length == 6
    let canClear = answers.filter(x => x != null).length > 0
    //  animation: "wiggle 2s linear infinite"}
    //
    let antimation = ""
    if(wrongAnswer)
    {
        antimation = "wiggle 2s linear infinite"
    }
    if(castSpell)
    {
        antimation = "spin 10s linear"
    }
    return (
        <div>
            <Grid container spacing={2} style={{ padding: 10, animation: antimation }}>

                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/Rabbit.png" />  </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/oracle.png" />  </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/dragon.png" />  </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/catus.png" />  </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/athena.png" />  </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item><img width={"100%"} className='grayscale' src="images/phoenix.png" />  </Item>
                </Grid>
                {hasSpell && (
                        <Grid item xs={12} style={{textAlign:"center"}}  >
                                <img  style={{maxHeight:200}} src="images/fire-sphere-spell.gif" /><br/>
                           You have the FIRE Spell!
                         </Grid>
                )
                }
                {!hasSpell && (
                 <>  
                {answers.map(x => (
                    <Grid item xs={2}>
                        <ItemBorder   >
                            {x != null && (
                                <SymbolSmall charCode={cords[x]} />
                            )}
                            {x == null && (
                                <SymbolSmall charCode={"_"} />
                            )}
                        </ItemBorder>
                    </Grid>
                ))}


                {cords.map(x => (
                    <Grid item xs={4}>
                        <ItemBorder onClick={() => {
                            let index = cords.indexOf(x)
                            clickValue(index)
                        }}>
                            <Symbol charCode={x} />
                        </ItemBorder>
                    </Grid>
                ))}
                <Grid item xs={4}>
                    {canClear && (
                        <ItemBorder onClick={() => {
                            setAnswers(blackAns)
                            localStorage.removeItem("answers")
                        }}>
                            Clear
                        </ItemBorder>)}
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                    {canCast && (
                        <ItemBorder style={{ backgroundColor: "darkgreen" }} onClick={() => {
                            let correct = true;
                            debugger
                            for (var i in answers) {
                                if (answers[i] != rightAnswer[i]) {
                                    correct = false;
                                }
                            }
                            if(!correct)
                            {
                                correct = true;
                                for (var i in answers) {
                                    if (answers[i] != rightAnswer2[i]) {
                                        correct = false;
                                    }
                                }
                            }

                          
                            if (!correct) {
                                setWrongAnswer(true);
                                G.default().State["spellAnswers"] = blackAns;
                                G.default().saveState();
                                setTimeout(() => {
                                  //  setAnswers(blackAns)
                                    setWrongAnswer(false);
                                }, 2000)
                            }
                            else
                            {
                                setCastSpell(true);
                                G.default().State["hasSpell"] = "TRUE";
                                G.default().saveState();
                                setTimeout(() => {
                                    setCastSpell(false);
                                    setHasSpell(true)
                                }, 5000)
                            }


                        }}>
                            CAST!
                        </ItemBorder>
                    )}
                    
                </Grid>
                </>
                )}
            </Grid>
        </div>
    )

}   