import React, { useEffect } from 'react';
import logo from './logo.svg';
import Shell from './Shell/Shell'
import './App.css';
import { HashRouter as Router,  Route, HashRouter } from 'react-router-dom'
import * as G from './GameData'
import ImgCache from './ImgCache'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Splash from './Pages/Splash';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
let list:any = []

function App() {
  const [error, setError] = React.useState<any|undefined>(undefined);
  const [loading,setLoading] = React.useState<boolean>(true);
  const [lastText, setLastText] = React.useState<string>("");
   useEffect(()=>{
      let f = async ()=>{
        try
        {
          await G.Init()
          setLoading(false);
          let img = new Array<any>()
          G.default().Locations.forEach(x=>{
           // ImgCache(x.Image)
         
          })
          
        
       // preloadImages(img);

        }
        catch(e)
        {
          setError(e)
        }
      }
      f()
   },[])

   if(error)
   {
     return (<div>{error}</div>)
   }
   if(loading)
   {
     return (<div><Splash /></div>)
   }
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
    <Router>
        <Shell />
        <canvas id="canvas" />
    </Router>
    </ThemeProvider>
    </div>
  );
}

export default App;
